import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import SidebarLinks from "../../components/SidebarLinks"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { Link } from "gatsby"

export default function ColleenEJacoby() {
  return (
    <Layout>
      <GatsbySeo
        title="Colleen E. Jacoby | Tecklenburg, Jenkins & Jacoby, LLC | Charleston, SC"
        description="Colleen E. Jacoby is a Michigan native and an associate attorney at
            Tecklenburg, Jenkins & Jacoby. Colleen graduated from the University of
            Minnesota-Duluth and the Charleston School of Law."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/attorneys/colleen-e-jacoby/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <SidebarLinks />
        </section>
        <section className={styles.content}>
          <h1>Colleen E. Jacoby</h1>
          <StaticImage
            src="../../images/attorneys/colleen-e-jacoby.jpg"
            className={styles.attorneyImage}
            width={300}
            alt="Colleen E. Jacoby"
          />
          <p>
            Colleen E. Jacoby is a Michigan native and an attorney at
            Tecklenburg, Jenkins & Jacoby. Colleen graduated from the University
            of Minnesota-Duluth and the Charleston School of Law. She played
            college ice hockey in Minnesota and continues to play in the adult
            hockey leagues in Charleston. Colleen was the vice president of the
            Maritime Law Society during law school and received a Maritime
            Honors Designation upon graduation. Colleen concentrates on maritime
            law and civil litigation and is active in many different maritime
            organizations in Charleston.
          </p>
          <h2>Bar Admissions:</h2>
          <ul>
            <li>
              <h3>State of South Carolina</h3>
            </li>
            <li>
              <h3>State of North Carolina</h3>
            </li>
          </ul>
          <h2>Education:</h2>
          <ul>
            <li>
              <h3>University of Minnesota – Duluth – Bachelor of Arts</h3>
            </li>
            <li>
              <h3>Charleston School of Law – Juris Doctor</h3>
            </li>
          </ul>
          <h2>Professional Associations and Memberships:</h2>
          <ul>
            <li>
              <h3>
                <a href="https://propellerclub.us/">
                  The Propeller Club of the United States
                </a>{" "}
                – Member
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.seali.com/">
                  The Southeastern Admiralty Law Institute
                </a>{" "}
                – Member
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://mlaus.org/">
                  Maritime Law Association of the United States
                </a>{" "}
                – Member
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://www.cwitsc.org/">Charleston Women in Trade</a>{" "}
                – Member
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://wistainternational.com/">
                  Women’s International Shipping & Trading Association (WISTA)
                </a>{" "}
                – Member
              </h3>
            </li>
          </ul>
          <p className={styles.attorneyFooter}>
            <Link to="/contact">Contact</Link>
            &nbsp;&nbsp;&nbsp;{" "}
            <a href="/Colleen-E.-Jacoby.vcf" download="Colleen-E-Jacoby.vcf">
              Download VCard
            </a>
            &nbsp;&nbsp;&nbsp;{" "}
            <a href="https://www.linkedin.com/in/colleenejacoby/">
              View LinkedIn Profile
            </a>
          </p>
        </section>
      </section>
    </Layout>
  )
}
